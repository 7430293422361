
function openNav() {
    // nav menu and mobileButton's 3 bars
    var navBar = document.getElementById('mobileMenu'),
        bar1 = document.getElementById('bar1'),
        bar2 = document.getElementById('bar2'),
        bar3 = document.getElementById('bar3');

    if (navBar.style.transform !== "translateY(0px)") {
        //opens mobile nav menu and adds X animation to mobileButton
        navBar.style.transform = "translateY(0px)";
        bar1.style.animation = "topX .5s forwards";
        bar2.style.animation = "midX .5s forwards";
        bar3.style.animation = "bottomX .5s forwards";
    } else {
        //closes mobile nav menu and adds hamburger animation to mobileButton
        navBar.style.transform = "translateY(-500px)";
        bar1.style.animation = "topRX .5s both";
        bar2.style.animation = "midRX .5s both";
        bar3.style.animation = "bottomRX .5s both";
    }
}